import React from 'react';
import {Descriptions, Table} from "antd";
import {Link, useParams} from "react-router-dom";
import {useFetch} from "../../api";
import {Shell} from "../../components/shell";

const dataSource = [
    {
        key: '1',
        name: 'دفعة تصاميم',
        date: '10/3',
        type: "الوارد",
        invoiceType: "weekly-salary",
        totalUSD: 5000,
        totalIDQ: 0,
    },
    {
        key: '1',
        name: 'شراء شريط /19/ 5   /4',
        date: '10/3',
        type: "المصروف",
        invoiceType: "Invoice",
        totalUSD: 0,
        totalIDQ: 345000,
    }, {
        key: '1',
        name: 'شراء خشب + مواد /الملا19/ 5/ 22',
        date: '10/3',
        type: "المصروف",
        invoiceType: "Invoice",
        totalUSD: 0,
        totalIDQ: 17000,
    }, {
        key: '1',
        name: 'اجور رافعه شوكيه  20  2/ 6/ 2022',
        date: '10/3',
        invoiceType: "Invoice",
        type: "السلف",
        totalUSD: 0,
        totalIDQ: 2825000,
    }
]

export function Worker(props) {
    let {id} = useParams();

    const { data, isLoading } = useFetch({ url: `/worker/${id}` })


    const columns = [
        {
            title: 'المشروع',
            dataIndex: 'name',
            key: 'name',
            render: (text, row) => <Link
                to={row.invoiceType === "weekly-salary" ? `/weekly-salary/${row.key}` : `/invoice/${row.key}`}>{text}</Link>,

        },
        {
            title: 'date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'totalUSD',
            dataIndex: 'totalUSD',
            key: 'totalUSD',
        },
        {
            title: 'invoiceType',
            dataIndex: 'invoiceType',
            key: 'invoiceType',
        },
        {
            title: 'total-IQD',
            dataIndex: 'totalIDQ',
            key: 'totalIDQ',
        },
    ];

    return (
        <Shell currentPage="project">
            <div className="flex flex-col">
                <Link to="/">Go To Main page</Link>
                <div>
                    <Descriptions title="Project Info" bordered>
                        <Descriptions.Item label="Project Code">{data?.code}</Descriptions.Item>
                        <Descriptions.Item label="Project Name">{data?.name}</Descriptions.Item>
                        <Descriptions.Item label="Status">{data?.status}</Descriptions.Item>
                    </Descriptions>
                </div>
                <div className="mt-10">
                    <Table dataSource={dataSource} columns={columns}/>
                </div>
            </div>
        </Shell>
    );
}
