import {Button, Popconfirm, Table} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMemo} from "react";
import {sum, sumBy} from "lodash";

import {useFetch, useMutation} from "../../api";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {PageSpinner} from "../../components/page-spinner";
import {formatNumber} from "../../utils/utils";
import {useQueryClient} from "react-query";
import moment from "moment";
import {useAuth} from "../../security";

export function Wage(props) {
    let {id} = useParams();
    const {isAdmin} = useAuth()


    const {data: wage = [], isLoading: isLoadingWage} = useFetch({url: `/wage/${id}`})

    const {data: proj, isLoading: isLoadingProject} = useFetch({url: `/project`})
    const {data: wrk, isLoading: isLoadingWorker} = useFetch({url: `/worker`})

    const isLoading = isLoadingWorker || isLoadingProject || isLoadingWage

    const projects = proj?.results || []
    const worker = wrk?.results || []

    const newData = worker?.map(i => {

        const workerLines = wage?.lines?.filter(j => j.worker.id === i.id)
        const totalDays = sumBy(workerLines, 'days')
        const cost = workerLines?.[0]?.cost || 0

        return {
            ...i,
            cost,
            totalDays,
            total: totalDays * cost
        }
    })

    const useRemove = useMutation({url: `/wage/${id}`, method: "DELETE"})
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const onDelete = async () => {
        useRemove.mutate({id}, {
            onSuccess: (newData) => {
                queryClient.removeQueries(`/wage/${id}`, {exact: true})

                queryClient.setQueryData('/wage', (oldData = []) => {
                    return {...oldData, results: oldData.results.filter(i => i.id !== newData.id)}
                })

                navigate('/wage');
            },
        })
    };

    const columns = [
        {
            accessorKey: 'name',
            id: 'name',
            header: 'العامل',
            // cell: info => <Link to={`/wage/${info.row.original.id}`}>{info.getValue()}</Link>,
        },
        {
            accessorKey: 'cost',
            id: 'cost',
            header: 'اليومية',
            cell: info => formatNumber(info.getValue(), null, 0),

        },
        ...projects?.map(i => ({
            accessorKey: i.id,
            id: i.id,
            header: i.name,
            accessorFn: row => wage?.lines?.find(j => j?.worker?.id === row.id)?.project?.find(j => j.id === i.id)?.days || 0,
            // cell: info => formatNumber(info.getValue()),
        })),
        {
            accessorKey: 'totalDays',
            id: 'totalDays',
            header: 'عدد الآيام',
            // cell: info => formatNumber(info.getValue()),

        },
        {
            accessorKey: 'total',
            id: 'total',
            header: 'المجموع',
            cell: info => formatNumber(info.getValue(), null, 0),

        },
    ]


    //  navigate(`/wage/${newData.id}`, {replace: true});
    return <Shell currentPage="wage">
        <PageSpinner loading={isLoading}>
            <OwlTable
                data={newData || []}
                columns={columns}
                title={<span>الآسبوعيات من {moment(wage?.date?.start).format("DD-MM")} الي {moment(wage?.date?.end).format("DD-MM")}</span>}
                subtitle={'كشف اسبوعيات العمال.'}
                buttons={[isAdmin && <Popconfirm
                    title="هل انت متآكد ؟"
                    onConfirm={onDelete}
                    okText="نعم"
                    cancelText="كلا"
                >
                    <Button type="primary" danger>
                        حذف
                    </Button>
                </Popconfirm>,
                    isAdmin && <Button type="primary" onClick={() =>  navigate(`/wage/${id}/edit`, {replace: true})}>
                        تعديل
                    </Button>
                ]}
            />
        </PageSpinner>
    </Shell>
}
