import {Alert, Button, Form, Input} from 'antd'
import {useState} from 'react'
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

import {Layout} from '../../../components/layout'
import {useAuth} from '../../../security'
import {rules} from "../../../utils/rules"

export function SingupPage() {
    const {singup, loading} = useAuth()

    const navigate = useNavigate()
    const location = useLocation()
    const [error, setError] = useState()

    const {from} = location.state || {from: {pathname: '/'}}

    const onFinish = async (values) => {
        const {isSuccess, data} = await singup(values)

        console.log({data})
        if (isSuccess) {
            navigate(from, {replace: true});
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Layout>
            <div className="h-full flex items-center justify-center">
                <div>
                    <div className="text-5xl mb-10 text-center">
                        Teraz<strong>ERP</strong>
                    </div>
                    <div>
                        <pre>sign-up</pre>
                    </div>

                    <div className="text-center p-5">
                        <Form
                            name="login"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item label="الآسم" name="name" rules={rules.input}>
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label="Username"
                                name="email"
                                rules={rules.input}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item label="Password" name="password" rules={rules.password}>
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item
                                label="Password Confirm"
                                name="password-confirm"
                                rules={[
                                    ...rules.password,
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject(
                                                'The two passwords that you entered do not match!'
                                            )
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" loading={loading} htmlType="submit">
                                    Sign Up
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
