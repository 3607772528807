import React, {useMemo} from 'react';
import {Link} from "react-router-dom";

import {useFetch} from "../../api";
import AddProject from "./add-project";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {EditProject} from "./edit-project";
import PageHeading from "../../components/page-heading";
import {RefreshButton} from "../../components/refresh-button";
import {useAuth} from "../../security";

export function Projects() {

    const {data = {}, isLoading, fetch} = useFetch({url: '/project'})
    const {isAdmin} = useAuth()

    const columns = useMemo(() => [
        isAdmin && {
            id: 'actions',
            accessorKey: 'actions',
            header: 'آوامر',
            cell: info => <div>
                <EditProject id={info.row.original.id}/>
            </div>,
        },
        {
            id: 'code',
            accessorKey: 'code',
            header: 'الرمز',
            cell: info => <Link to={`/project/${info.row.original.id}`}>{info.getValue()}</Link>,
        },
        {
            id: 'name',
            accessorKey: 'name',
            header: 'المشروع',
            cell: info => <Link to={`/project/${info.row.original.id}`}>{info.getValue()}</Link>,
        },
        {
            accessorKey: 'description',
            id: 'description',
            header: 'المواضفات',
        },
        {
            accessorFn: row => row?.status === 'Active' ? 'فعال' : 'متوقف',
            id: 'status',
            header: 'الحالة',
        },
    ], [data, isLoading]);

    console.log(columns)
    return (
        <Shell currentPage="project">
            <div className="flex flex-col">
                <PageHeading
                    title={'المشاريع'}
                    subtitle={'يمكنك آضافة و تعديل المشاريع و مشاهدة الآسبوعيات و الفواتير الخاصة بها.'}
                />
                <OwlTable
                    data={data?.results || []}
                    columns={columns}
                    buttons={[isAdmin && <AddProject/>, <RefreshButton fetch={fetch} loading={isLoading}/>]}
                    isLoading={isLoading}
                />
            </div>
        </Shell>
    );
}
