import {Button, Space} from 'antd'
import {Link} from 'react-router-dom'

import {useAuth} from '../../security'

export function Logo() {
    return (
        <div className="text-xl">
            Axio<strong>matic</strong>
        </div>
    )
}

export function Header() {
    const {isAuthenticated, logout} = useAuth()

    const subTitle = 'لإدارة الآعمال و الشركات'

    return (
        <div
            className="sticky top-0 z-10 flex w-full items-center justify-between px-6 h-16 bg-white border-b border-gray-200">
            <Space className="flex items-center">
                <Logo/>
                <span className="text-gray-400">{subTitle}</span>
            </Space>
            <div className="flex items-center">
                <div className="hidden md:block md:flex md:justify-between md:bg-transparent space-x-2">
                    {isAuthenticated ? (
                        <>
                            <Button onClick={logout} type="primary">
                                تسجيل الخروج
                            </Button>
                        </>
                    ) : (
                        <div className="bg-red-200">
                            <Link key="sing-up" to="/singup">
                                <Button type="primary">تسجيل حساب</Button>
                            </Link>
                            <Link key="sign-in" to="/login">
                                <Button>تسجيل الدخول</Button>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
