import {Button, DatePicker, Form, Input, InputNumber, Modal, Select} from "antd";
import {useState} from "react";
import {rules} from "../../utils/rules";
import {useFetch, useMutation} from "../../api";
import {useQueryClient} from "react-query";
import moment from "moment";
import {pick} from "lodash";
import {FileAddOutlined} from "@ant-design/icons";


export function AddInvoice({currentProject, fetch = () => null})  {

    const [form] = Form.useForm();
    const queryClient = useQueryClient()

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true)
        form.resetFields()
    }
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }

    const usePost = useMutation({url: '/invoice'})

    const {data: {results: projects = []} = {}, isLoading} = useFetch({url: '/project'})

    const onOk = async () => {
        const values = await form.validateFields()
        values.invoiceType = 'Invoice'
        values.project = pick(projects.find(i => i.id === values.project), ['id', 'name'])
        usePost.mutate(values, {
            onSuccess: (newData) => {
                queryClient.setQueryData('/invoice', (oldData = []) =>
                    ({...oldData, results: [...oldData.results, newData]}))
                setIsModalVisible(false)
                fetch()
            },
        })
    };

    const usd = Form.useWatch(['total', 'usd'], form);
    return (<div>
        <Button type="primary" onClick={showModal} icon={<FileAddOutlined/>}>
            إضافه فاتورة جديدة
        </Button>
        <Modal
            title="إضافه فاتورة جديدة"
            centered
            width={'70%'}
            destroyOnClose
            visible={isModalVisible}
            okText={"إضافة"}
            cancelText={"إلغاء"}
            onOk={onOk}
            onCancel={onCancel}>
            <Form
                name="basic"
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                initialValues={{
                    total: {
                        usd: 0,
                        iqd: 0
                    },
                    type: 'Debit',
                    project: currentProject,
                    date: moment(),
                }}
                autoComplete="off"
            >
                <Form.Item label="الوصف" name="name" rules={rules.input}>
                    <Input/>
                </Form.Item>

                <Form.Item label="تاريخ" name="date" rules={rules.date}>
                    <DatePicker style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item label="الصنف" name="category" rules={rules.input}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="نوع"
                    name="type"
                    rules={rules.select}
                >
                    <Select>
                        <Select.Option value="Debit">المصروف</Select.Option>
                        <Select.Option value="Credit">الوارد</Select.Option>
                    </Select>
                </Form.Item>


                <Form.Item label="دولار" name={['total', 'usd']} rules={rules.input}>
                    <InputNumber style={{width: '100%'}} min={0}/>
                </Form.Item>

                {usd > 0 && (<Form.Item label="سعر التحويل" name={['total', 'exchangeRate']} rules={rules.input}>
                    <InputNumber style={{width: '100%'}} min={0}/>
                </Form.Item>)}

                <Form.Item label="دينار" name={['total', 'iqd']} rules={rules.input}>
                    <InputNumber style={{width: '100%'}} min={0}/>
                </Form.Item>


                <Form.Item
                    label="المشروع"
                    name="project"
                    rules={rules.select}
                >
                    <Select>
                        {projects.map(i => (<Select.Option value={i.id}>{i.name}</Select.Option>))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    </div>);
}
