import {formatNumber} from "../../utils/utils";

export function TotalWidget({data, title}) {

    const Widget = ({name, stat, currency = null, round = 2}) =>
        <div key={name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:px-3 sm:pt-6 sm:pr-6">
            <dt className="text-sm font-medium text-gray-500 truncate">{name}</dt>
            <dd className="mt-1 text-2xl font-semibold text-gray-900">{formatNumber(stat, currency, round)}</dd>
        </div>


    return (
        <div>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                <Widget name={`${title} بالدولار`} stat={data.totalUSD} currency={'USD'}/>
                <Widget name={`${title} بالدينار `} stat={data.totalIQD} currency={null} round={0}/>
                <Widget name={`${title} الكلي بالدينار `} stat={data.total} currency={null} round={0}/>

            </dl>
        </div>
    );
}
