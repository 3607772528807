import React, {useMemo} from 'react';
import moment from "moment";

import {useFetch} from "../../api";
import AddExchange from "./add-exchange";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {EditExchange} from "./edit-exchange";
import {formatNumber} from "../../utils/utils";
import {RefreshButton} from "../../components/refresh-button";
import PageHeading from "../../components/page-heading";
import {useAuth} from "../../security";

export function Exchanges() {

    const {isAdmin} = useAuth()

    const {data = {}, isLoading, fetch} = useFetch({url: '/exchange'})

    const columns = useMemo(() => [
        isAdmin && {
            id: 'actions',
            accessorKey: 'actions',
            header: 'آوامر',
            cell: info => <div>
                <EditExchange id={info.row.original.id}/>
            </div>,
        },
        {
            id: 'code',
            accessorKey: 'code',
            header: 'الرمز',
        },
        {
            accessorFn: row => moment(row.date).format('DD-MM-YYYY'),
            id: 'date',
            header: 'التاريخ'
        },
        {
            accessorFn: row => row?.from?.amount || 0,
            cell: info => formatNumber(info.getValue(), info.row.original?.from?.currency),
            id: 'from',
            header: 'من',
        },
        {
            accessorFn: row => row?.to?.amount || 0,
            cell: info => formatNumber(info.getValue(), info.row.original?.to?.currency),
            id: 'to',
            header: 'الى',
        },
        {
            accessorKey: 'employee',
            id: 'employee',
            header: 'الموضف',
        },
        {
            accessorKey: 'description',
            id: 'description',
            header: 'ملاحظات',
        },
    ], [data, isLoading]);


    return (
        <Shell currentPage="exchange">
            <PageHeading
                title={'تصريف عملة'}
                subtitle={'كشف تصريفات العملات'}
            />
            <OwlTable
                data={data?.results || []}
                columns={columns}
                isLoading={isLoading}
                buttons={[isAdmin && <AddExchange/>, <RefreshButton fetch={fetch} loading={isLoading}/>]}
            />
        </Shell>
    );
}
