export const rules = {
	select: [{ required: true, message: 'آرجو اختيار من هذه الخيارات' }],
	input: [{ required: true, message: "لا يمكن ان يكون هذا الحقل فارغ!" }],
	password: [{ required: true, message: 'عليك إدخال رسز السر!' }],
	email: [
		{ required: true, message: 'يجب عليك إدخال إيميل!' },
		{ type: 'email' },
	],
	date: [{ required: true, message: 'عليك إدخال تاريخ هنا!' }],
}
