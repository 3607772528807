import {useMutation as useMutationReactQuery, useQuery, useQueryClient} from 'react-query'
import {message} from 'antd'

import {useAuth} from '../security'
import {handelRequests} from './api-wrapper'
import config from '../config.json'


const {SERVER_URL_PRODUCTION, SERVER_URL_DEV} = config


export const isDev = process.env.NODE_ENV === "development"
export const SERVER_URL = isDev ? SERVER_URL_DEV : SERVER_URL_PRODUCTION

const defaultQueryOptions = {
    staleTime: 5000 * 60, // 5 min
}

function getFullUrl(url) {
    if (url.indexOf('https://') === 0 || url.indexOf('http://') === 0) {
        return url
    }

    return SERVER_URL + url
}

export function useFetch({
                             url,
                             queryKey = url,
                             map,
                             isExternal = false,
                             dontFetch = false,
                             ...queryOptions
                         }) {
    queryOptions = {...defaultQueryOptions, ...queryOptions}
    const {accessToken} = useAuth()
    const queryClient = useQueryClient()

    const headers = {
        'Content-Type': 'application/json',
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    }

    async function fetchURL() {
        const data = await handelRequests({
            url: isExternal ? url : getFullUrl(url),
            headers,
        })

        return map ? map(data) : data
    }

    const query = useQuery(queryKey, fetchURL, {
        staleTime: 5000 * 60,
        refetchOnWindowFocus: false,
        refetchInactive: true,
        enabled: !dontFetch,
        onError: (error) => message.error(error.message || error),
        ...queryOptions,
    })
    return {
        ...query,
        isLoading: query.isFetching || query.isLoading,
        fetch: () => {
            queryClient.invalidateQueries(queryKey)
            query.refetch()
        },
    }
}

export function useMutation({url, method = 'POST', map}) {
    const {accessToken} = useAuth()
    const headers = {
        'Content-Type': 'application/json',
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    }

    async function triggerMutation(data) {
        const result = await handelRequests({
            url: getFullUrl(url),
            headers,
            method,
            data,
        })

        return map ? map(result) : result
    }

    return useMutationReactQuery(triggerMutation)
}
