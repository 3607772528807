import React from 'react';
import {classNames} from "../../utils/utils";
import {
    AcademicCapIcon,
    BadgeCheckIcon,
    CashIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon
} from "@heroicons/react/outline";


const actions = [
    {
        title: 'المشاريع',
        href: '/project',
        icon: ClockIcon,
        iconForeground: 'text-teal-700',
        iconBackground: 'bg-teal-50',
        text: `قائمة المشاريع مع كشف الفواتير.`
    },
    {
        title: 'الفواتير',
        href: '/invoice',
        icon: BadgeCheckIcon,
        iconForeground: 'text-purple-700',
        iconBackground: 'bg-purple-50',
        text: `كشف الفواتير.`
    },
    {
        title: 'الآسبوعيات',
        href: '/wage',
        icon: UsersIcon,
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
        text: `قائمة الاسبوعيات الخاصة العمال مع خلاصة الرواتب.`
    },
    {
        title: 'الديون',
        href: '/loan',
        icon: CashIcon,
        iconForeground: 'text-yellow-700',
        iconBackground: 'bg-yellow-50',
        text: `كشف الديون مع خلاصة الباقي.`
    },
    {
        title: 'العمال',
        href: '/worker',
        icon: ReceiptRefundIcon,
        iconForeground: 'text-rose-700',
        iconBackground: 'bg-rose-50',
        text: `قاىمة بآسماء العاملين علي المشاريع.`
    },
    {
        title: 'تصريف العملة',
        href: '/exchange',
        icon: AcademicCapIcon,
        iconForeground: 'text-indigo-700',
        iconBackground: 'bg-indigo-50',
        text: `تحويلات العملات بين الدولار و الدينار.`
    },
]

export function NavBox(props) {
    return (
        <div>
            <div
                className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                {actions.map((action, actionIdx) => (
                    <div
                        key={action.title}
                        className={classNames(
                            actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                            actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                            'relative group bg-white hover:bg-gray-100 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                        )}
                    >
                            <div>
            <span
                className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white group-hover:ring-gray-100 group-hover:text-gray-100 group-hover:bg-gray-400'
                )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true"/>
            </span>
                            </div>
                            <div className="mt-8">
                                <h3 className="text-lg font-medium">
                                    <a href={action.href} className="focus:outline-none group-hover:text-gray-500">
                                        {/* Extend touch target to entire panel */}
                                        <span className="absolute inset-0" aria-hidden="true"/>
                                        {action.title}
                                    </a>
                                </h3>
                                <p className="mt-2 text-sm text-gray-500">
                                    {action.text}
                                </p>
                            </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
