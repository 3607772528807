import {Link} from "react-router-dom";

export function About(props) {
    return (
        <div>
            About Page
            <Link to="/">Go To Main page</Link>


        </div>

    );
}
