import {Button, Form, Input} from 'antd'
import {useLocation, useNavigate} from "react-router-dom";

import {useAuth} from '../../../security'
import {rules} from '../../../utils/rules'
import logo from '../../../logo.png'
import Footer from "../../../components/shell/footer";
import moment from "moment";

export function LoginPage() {
    const {login, isLoading} = useAuth()

    const navigate = useNavigate()
    const location = useLocation()

    const {from} = location.state || {from: {pathname: '/'}}

    const onFinish = async (values) => {
        const {isSuccess} = await login(values)

        if (isSuccess) {
            navigate(from, {replace: true});

        }
    }


    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">

                <div className="flex flex-col items-center">
                    <img src={logo} alt="Logo" className="h-28 mix-blend-multiply"/>
                </div>

                <h2 className="mt-6 text-center text-3xl font-extrabold text-indigo-600">منظومة الحسابات</h2>

            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mx-2">
                    <div className="text-5xl mb-5 text-center">
                        Axio<strong>matic</strong>
                    </div>

                    <Form
                        style={{direction: 'ltr'}}
                        name="login"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 20}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="الأيميل"
                            name="email"
                            rules={rules.input}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="رقم السر"
                            name="password"
                            rules={rules.password}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Button type="primary" className="w-full mt-10" loading={isLoading} htmlType="submit">
                            تسجيل الدخول
                        </Button>
                    </Form>
                </div>
            </div>
            <div className="mt-3" style={{direction: "ltr"}}>
                <p className="text-center text-base text-gray-400">&copy; {moment().year()} {' '}
                    <a href="https://mustafaihssan.com">Axio<strong>matic</strong></a>,
                    All rights reserved.</p>
            </div>
        </div>
    )
}
