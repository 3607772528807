import React from 'react';

function TablePagination({table}) {

    const currentPage = table.getState().pagination.pageIndex + 1
    const pageCount = table.getPageCount()

    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        >
            {pageCount > 1 && (
                <div className="flex-1 flex justify-start">
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className="mx-2 relative inline-flex items-center px-4 py-2 border border-gray-300
                    text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50
                    disabled:bg-gray-300  disabled:text-gray-500 disabled:cursor-not-allowed"
                    >
                        السابق
                    </button>
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  disabled:bg-gray-300  disabled:text-gray-500 disabled:cursor-not-allowed"
                    >
                        التالي
                    </button>
                </div>
            )}
            <div className="text-sm text-gray-700">
                الصفحة <span className="font-medium">{currentPage}</span> من {' '}
                <span className="font-medium">{pageCount}</span>
            </div>
        </nav>
    );
}

export default TablePagination;
