import {Button, DatePicker, Form, Input, InputNumber, Modal, Select} from "antd";
import {useState} from "react";
import {useFetch, useMutation} from "../../api";
import {useQueryClient} from "react-query";
import moment from "moment";
import {ExchangeForm} from "../../components/forms/exchange";
import {FileAddOutlined} from "@ant-design/icons";


export function AddExchange(props) {

    const [form] = Form.useForm();
    const queryClient = useQueryClient()

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true)
        form.resetFields()
    }
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }
    const usePost = useMutation({url: '/exchange'})


    const handleOk = async () => {
        const values = await form.validateFields()

        values.to.currency = values.from.currency ? 'IQD' : 'USD'
        usePost.mutate(values, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.setQueryData('/exchange', (oldData = []) =>
                    ({...oldData, results: [...oldData.results, newData]}))
            },
        })
    };


    return (<div>
        <Button type="primary" onClick={showModal} icon={<FileAddOutlined/>}>
            إضافة جديد
        </Button>
        <Modal
            title="إصافة دين جديد"
            centered
            width={'70%'}
            destroyOnClose
            visible={isModalVisible}
            okText={"إضافة"}
            cancelText={"إلغاء"}
            onOk={handleOk}
            onCancel={onCancel}>
            <Form
                name="basic"
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                initialValues={{
                    from: {amount: 0, currency: 'USD'},
                    to: {amount: 0, currency: 'IQD'},
                    date: moment(),
                }}
                autoComplete="off"
            >
                <ExchangeForm form={form}/>
            </Form>
        </Modal>
    </div>);
}

export default AddExchange;
