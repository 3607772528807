import moment from "moment";
import accounting from 'accounting'
import getSymbolFromCurrency from 'currency-symbol-map'

export function strip(text = '') {
    return text.replace(/<[^>]*>?/gm, '')
}

export function formatNumber(x = 0, currency, decimal = 2) {
    const w = accounting.formatMoney(
        x,
        getSymbolFromCurrency(currency) || '',
        currency === 'IQD' ? 0 : decimal
    )

    if (x === 0 || w === 0) return x
    return w
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
