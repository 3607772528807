import {useMemo, useState, useEffect} from 'react';
import {Tabs} from "antd";
import {Link, useParams} from "react-router-dom";
import moment from "moment";
import {isEmpty, pick, sum, sumBy} from "lodash";

import {useFetch} from "../../api";
import {Shell} from "../../components/shell";
import {AddInvoice} from "../invoices/add-invoice";
import {OwlTable} from "../../components/owl-table";
import {formatNumber} from "../../utils/utils";
import {TotalWidget} from "../../components/total-widget";
import {EditInvoice} from "../invoices/edit-invoice";
import {EditProject} from "../projects/edit-project";
import {PageSpinner} from "../../components/page-spinner";
import {useAuth} from "../../security";

export function Project() {
    let {id} = useParams();
    const {isAdmin} = useAuth()

    const dateF = (d) => moment(d).format('DD-MM')

    const {data, isLoading: isLoadingProject} = useFetch({url: `/project/${id}`})
    const {data: {results: _invoices = []} = {}, isLoading: isLoadingInvoices} = useFetch({
        url: `/invoice?project=${id}`,
        queryKey: '/invoice',
        dontFetch: isEmpty(id)
    })


    const {data: wages = [], isLoading: isLoadingWages} = useFetch({
        url: `/wage/project/${id}`,
        dontFetch: isEmpty(id)
    })

    const isLoading = isLoadingProject || isLoadingInvoices || isLoadingWages

    const [invoices, setInvoices] = useState([]);
    const [reshapedWages, setReshapedWages] = useState([]);

    const fetch = () => {
        window.location.reload();
    }


    useEffect(() => {
        console.log('in')
        const cW = wages.map(i => {
            return {
                id: i.id,
                project: pick(data, ['id', 'name']),
                total: {
                    "usd": 0,
                    "exchangeRate": 0,
                    "iqd": sumBy(i.lines, (e) => sumBy(e.project, 'days') * e.cost),
                    "tax": 0,
                    "discount": 0
                },
                "code": "AUTO-GEN",
                "name": `اسبوعيات من ${dateF(i.date.start)} الي ${dateF(i.date.end)}  `,
                "date": moment(i.date.start),
                "type": "Debit",
                "invoiceType": "wages",
                "category": "رواتب",
            }
        }).filter(i => i.total.iqd > 0)
        setReshapedWages(cW)

        console.log([...cW, ..._invoices].length)
        setInvoices([...cW, ..._invoices])
    }, [isLoading]);


    const columns = useMemo(() => [
        isAdmin && {
            id: 'actions',
            accessorKey: 'actions',
            header: 'آوامر',
            cell: info => info.row.original.invoiceType === 'wages' ? info.getValue() : <div>
                <EditInvoice fetch={fetch} id={info.row.original.id}/>
            </div>,
        },
        {
            id: 'code',
            accessorKey: 'code',
            header: 'الرمز',
        },
        {
            id: 'name',
            accessorKey: 'name',
            header: 'الآسم',
            cell: info => info.row.original.invoiceType === 'wages' ?
                <Link to={`/wage/${info.row.original.id}`}>{info.getValue()}</Link> : info.getValue(),

        },
        {
            accessorFn: row => moment(row.date).format('DD-MM-YYYY'),
            id: 'date',
            header: 'التاريخ'
        },
        {
            accessorFn: row => row.category,
            id: 'category', header: 'الصنف'
        },
        {
            accessorFn: row => row.type === 'Debit' ? 'المصروف' : 'الوارد',
            id: 'type', header: 'النوع'
        },
        {
            accessorFn: row => row.total?.usd || 0,
            cell: info => formatNumber(info.getValue(), 'USD'),
            id: 'total.usd',
            header: 'دولار'
        },
        {
            accessorFn: row => row.total?.exchangeRate || 0,
            cell: info => formatNumber(info.getValue()),
            id: 'total.exchangeRate',
            header: 'سعر التحويل'
        },
        {
            accessorFn: row => row?.total?.iqd || 0,
            cell: info => formatNumber(info.getValue()),
            id: 'total.iqd',
            header: 'دينار'
        },
    ], [data, isLoading]);

    const getStats = (data) => {
        const totalUSD = sumBy(data, 'total.usd')
        const totalIQD = sumBy(data, 'total.iqd')
        const totalUSDConverted = sum(data.map(i => Number(i.total.usd) * Number(i.total.exchangeRate)))
        const total = totalIQD + totalUSDConverted

        return {totalUSD, totalIQD, totalUSDConverted, total}
    }

    const debitInvoices = useMemo(() => invoices.filter(i => i.type === "Debit"), [invoices])
    const creditInvoices = useMemo(() => invoices.filter(i => i.type === "Credit"), [invoices])
    const totalDebit = useMemo(() => getStats(debitInvoices), [invoices])
    const totalCredit = useMemo(() => getStats(creditInvoices), [invoices])

    const balance = useMemo(() => totalCredit.total - totalDebit.total, [invoices])

    return (
        <Shell currentPage="project">
            <div className="flex flex-col">
                <div className="sm:flex-auto mt-5">
                    <h1 className="text-2xl font-semibold text-gray-900">[{data?.code}] {data?.name}</h1>
                    <p className="mt-2 text-xl text-gray-700">
                        {data?.description}
                    </p>
                </div>

                <PageSpinner loading={isLoading}>
                    {isAdmin && <div className="mb-5">
                        <EditProject id={id}/>
                    </div>}
                    <TotalWidget data={totalCredit} title={"الوارد"}/>
                    <TotalWidget data={totalDebit} title={"المصروف"}/>

                    <div className="sm:w-1/3 w-full mr-auto pr-3">
                        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:px-3 sm:pt-6 sm:pr-6">
                            <dt className="text-sm font-semibold text-gray-500 truncate">الصافي الاجمالي بالدينار</dt>
                            <dd className={`mt-1 text-2xl font-semibold ${balance > 0 ? 'text-green-600' : 'text-red-600'}`}>{formatNumber(balance, null, 0)}</dd>
                        </div>
                    </div>


                    <div className="mt-10">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="كل الفواتير" key="1">
                                <OwlTable
                                    data={invoices}
                                    columns={columns}
                                    loading={isLoadingInvoices}
                                    buttons={[isAdmin && <AddInvoice fetch={fetch} currentProject={id}/>]}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="الاسبوعيات فقط" key="wages">
                                <OwlTable
                                    data={reshapedWages}
                                    columns={columns}
                                    loading={isLoadingInvoices}
                                    buttons={[isAdmin && <AddInvoice fetch={fetch} currentProject={id}/>]}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="الواردة فقط" key="2">
                                <OwlTable
                                    data={creditInvoices}
                                    columns={columns}
                                    loading={isLoadingInvoices}
                                    buttons={[isAdmin && <AddInvoice fetch={fetch} currentProject={id}/>]}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="المصروف ققط" key="3">
                                <OwlTable
                                    data={debitInvoices}
                                    columns={columns}
                                    loading={isLoadingInvoices}
                                    buttons={[isAdmin && <AddInvoice fetch={fetch} currentProject={id}/>]}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </PageSpinner>
            </div>
        </Shell>
    );
}
