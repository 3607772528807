import {Spin} from 'antd'

export function PageSpinner({loading, children}) {
    return loading ? (
        <div className="flex items-center justify-center">
            <Spin/>
        </div>
    ) : (
        <div>{children}</div>
    )
}
