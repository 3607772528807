import {Button, Form, Modal, Popconfirm} from "antd";
import {useState} from "react";
import {useFetch, useMutation} from "../../api";
import {useQueryClient} from "react-query";
import {isEmpty} from "lodash";
import {PageSpinner} from "../../components/page-spinner";
import moment from "moment";
import {ModalFormFooter} from "../../components/modal-form-footer";
import {ExchangeForm} from "../../components/forms/exchange";


export function EditExchange({id}) {

    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {data, isLoading} = useFetch({url: `/exchange/${id}`, dontFetch: isEmpty(id) || !isModalVisible})

    const queryClient = useQueryClient()


    const showModal = () => {
        setIsModalVisible(true)
        form.resetFields()
    }
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }

    const usePatch = useMutation({url: `/exchange/${id}`, method: "PATCH"})
    const useRemove = useMutation({url: `/exchange/${id}`, method: "DELETE"})

    const onDelete = async () => {
        useRemove.mutate({id}, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.removeQueries(`/exchange/${id}`, {exact: true})

                queryClient.setQueryData('/exchange', (oldData = []) => {
                    return {...oldData, results: oldData.results.filter(i => i.id !== newData.id)}
                })
            },
        })
        setIsModalVisible(false)
    };


    const onOk = async () => {
        const values = await form.validateFields()

        if (data.code === values.code) delete values.code
        values.to.currency = values.from.currency ? 'IQD' : 'USD'

        usePatch.mutate(values, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.setQueryData(`/exchange/${id}`, () => newData)

                queryClient.setQueryData('/exchange', (oldData = []) =>
                    ({...oldData, results: oldData.results.map(i => i.id === id ? newData : i)}))
            },
        })
    };

    return (<div>
        <Button type="primary" onClick={showModal}>
            تعديل
        </Button>
        <Modal
            title="تعديل دين"
            centered
            width={'70%'}
            destroyOnClose
            visible={isModalVisible}
            onCancel={onCancel}
            footer={<ModalFormFooter {...{onOk, onCancel, onDelete}}/>}>
            <PageSpinner loading={isLoading}>
                <Form
                    name="basic"
                    form={form}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{
                        ...data,
                        date: moment(data?.date)
                    }}
                    autoComplete="off"
                >
                    <ExchangeForm form={form}/>
                </Form>
            </PageSpinner>
        </Modal>
    </div>);
}
