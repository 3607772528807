import {Alert, Button, DatePicker, Form, Input, InputNumber, Table} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {isEmpty, isNumber, omit, pick, sum, sumBy} from "lodash";

import {useFetch, useMutation} from "../../api";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {PageSpinner} from "../../components/page-spinner";
import {TableHead} from "../../components/owl-table/table-head";
import {TableBody} from "../../components/owl-table/table-body";
import TablePagination from "../../components/owl-table/table-pagination";
import {rules} from "../../utils/rules";
import {formatNumber} from "../../utils/utils";
import moment from "moment";
import {useQueryClient} from "react-query";
import {FileAddOutlined} from "@ant-design/icons";

export function WageEdit(props) {

    let {id} = useParams();

    const {data: wage = [], isLoading} = useFetch({url: `/wage/${id}`})

    const projects = wage?.lines?.[0]?.project.map(i => pick(i, ['id', 'name'])) || []
    const worker = wage?.lines


    const [wageEdit, setWageEdit] = useState([]);
    const [date, setDate] = useState(moment());

    useEffect(() => {
        if (!isEmpty(wage)) {
            // console.log(55, wage)
            const _newData = wage?.lines?.reduce((acc, i) => {
                return {
                    ...acc,
                    [i.worker.id]: {
                        ...i,
                        project: i.project?.reduce((_acc, j) => ({
                            ..._acc,
                            [j.id]: j.days || 0,
                        }), {})
                    }
                }
            }, {})
            // [i.id]: {
            //                 ...i,
            //                 cost: i?.salary,
            //                 totalDays: 0,
            //                 total: 0,
            //                 project: projects?.reduce((acc, i) => ({
            //                     ...acc,
            //                     [i.id]: null,
            //                 }), {})
            //             }
            //         }

            // console.log(9999, _newData)
            setWageEdit(Object.values(_newData))
            form.setFieldsValue(_newData)
            setDate(moment(wage?.date?.start))
        }
        //     setWage([])
    }, [isLoading]);

    const [form] = Form.useForm();


    const InputNum = ({name, rules: _rules, index = 0}) => <Form.Item
        name={name}
        rules={_rules}
        noStyle
    >
        <InputNumber
            min={0}
            style={
                {
                    background: index % 2 === 0 ? undefined : 'rgb(243 244 246)',
                    width: '100%',
                    outlineWidth: 0,
                    border: 'none'
                }}/>
    </Form.Item>


    const InputStatic = ({value}) => <Form.Item noStyle>
        <Input style={{width: '100%', outlineWidth: 0, border: 'none', background: 'rgb(243 244 246)'}}
               value={value}/>
    </Form.Item
    >


    const usePatch = useMutation({url: `/wage/${id}`, method: 'PATCH'})
    const queryClient = useQueryClient()
    const navigate = useNavigate()


    console.log(date.format("DD-MM-YYYY"))
    const handleOk = async () => {

        console.log(date)
        const _sendData = {
            date: {
                start: moment(date),
                end: moment(date).add(6, 'day'),
            }, lines: wageEdit.map(i => ({
                    ...i, project: Object.entries(i.project).reduce((acc, [key, val]) =>
                        [...acc, {...projects.find(i => i.id === key), days: val}], [])
                }
            ))
        }

        console.log('_sendData', _sendData.date)

        usePatch.mutate(_sendData, {
            onSuccess: (newData) => {
                queryClient.removeQueries('/wage')
                queryClient.removeQueries(`/wage/${newData.id}`)

                console.log('done')
                navigate(`/wage/${newData.id}`, {replace: true});
            },
        })
    };


    /// <Link to={`/wage/${info.row.original.id}`}>{info.getValue()}</Link>

    // console.log(wageEdit)

    return <Shell currentPage="wage">
        <PageSpinner loading={isLoading}>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">الآسبوعيات</h1>
                </div>
                <Button type="primary" onClick={handleOk} loading={usePatch.isLoading} className="mt-4"
                        icon={<FileAddOutlined/>}>
                    تعديل
                </Button>
            </div>

            <Form
                form={form}
                name="new-wage"
                initialValues={{...wage, date: {start: moment(wage?.date?.start)}}}
                onBlur={async (e) => {
                    const values = await form.validateFields()
                    const w = Object.entries(omit(values, ['date'])).map(([key, value]) => {
                        const _worker = worker.find(i => i.worker.id === key)?.worker
                        return {
                            cost: value.cost,
                            days: sum(Object.values(value.project)),
                            total: sum(Object.values(value.project)) * value.cost,
                            project: value.project,
                            worker: _worker,
                        }

                    })
                    setWageEdit(w)
                }}
            >

                <Form.Item
                    rules={rules.date}
                    label="اختار بداية الاسبوع"
                >
                    <DatePicker value={date} onChange={e => setDate(e)}/>
                </Form.Item>


                <div className="mt-8 flex flex-col w-full">
                    <div className="p-1 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                                <table className="min-w-full divide-y divide-gray-300  table-auto">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                                            العامل
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            اليومية
                                        </th>

                                        {projects.map(i => <th scope="col"
                                                               className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            {i.name}
                                        </th>)}

                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            عدد الآيام
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-56">
                                            المجموع
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {wageEdit?.map((j, index) => (
                                            <tr key={j.id}>
                                                <td className="whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100">
                                                    <InputStatic value={j.worker.name}/>
                                                </td>

                                                <td className="whitespace-nowrap text-sm text-gray-500">
                                                    <InputNum name={[j.worker.id, "cost"]}/>
                                                </td>

                                                {Object.keys(j.project)?.map(i =>
                                                    <td key={i}
                                                        className={`whitespace-nowrap text-sm text-gray-500 border-r-2 flex-1`}>
                                                        <InputNum name={[j.worker.id, 'project', i]}
                                                                  index={index}/>
                                                    </td>
                                                )}

                                                <td className="whitespace-nowrap text-sm text-gray-500 bg-gray-100">
                                                    <InputStatic value={j.days}
                                                                 name="totalDays"/>
                                                </td>

                                                <td className="whitespace-nowrap text-sm text-gray-500 bg-gray-100">
                                                    <InputStatic
                                                        value={formatNumber(j.total || 0, null, 0)}
                                                        name="total"/>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                    {/*<tfoot>*/}
                                    {/*<TableFooter table={table}/>*/}
                                    {/*</tfoot>*/}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </PageSpinner>
    </Shell>
}
