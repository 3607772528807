export default function PageHeading({title, subtitle, buttons}) {
    return (
        <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{title}</h2>
                <h4 className="text-gray-600 sm:text-sm sm:truncate">{subtitle}</h4>
            </div>

            <div className="mt-4 flex md:mt-0 md:ml-4 space-x-2 space-x-reverse">
                {buttons}
            </div>
        </div>
    )
}
