import {Shell} from "../../components/shell";
import {useFetch} from "../../api";
import {OwlTable} from "../../components/owl-table";
import {useMemo, useState} from "react";
import PageHeading from "../../components/page-heading";
import {sumBy} from "lodash";
import {formatNumber} from "../../utils/utils";
import {Segmented, Tabs, Tooltip, Form} from "antd";
import {ChevronDoubleUpIcon, ChevronDoubleDownIcon} from '@heroicons/react/outline'

export function Balance() {

    const {data = {}, isLoading, fetch} = useFetch({url: '/balance/all'})


    const columns = useMemo(() => [
        {id: 'name', accessorKey: 'name', header: 'الآسم'},
        {id: 'category', accessorKey: 'category', header: 'المصدر'},
        {
            accessorFn: row => row.total.usd,
            id: 'usd',
            header: 'الدولار',
            cell: info => {
                const converted = info?.row?.original?.total?.converted
                const conv = formatNumber(converted / info.getValue(), null, 0)
                return <Tooltip title={`${formatNumber(converted, null, 0)}  بالدينار   و سعر التحويل   ${conv} `}>
                    {formatNumber(Math.abs(info.getValue()), 'USD')}
                </Tooltip>
            }
        },
        {
            accessorFn: row => row.total.iqd,
            id: 'iqd',
            header: 'الدينار',
            cell: info => formatNumber(Math.abs(info.getValue()), null, 0),
        },
        {
            accessorFn: row => row.total.total,
            id: 'total',
            header: 'المجموع',
            cell: info => formatNumber(Math.abs(info.getValue()), null, 0),
        },
    ], [data, isLoading]);

    const Widget = ({name, stat, currency = null, round = 2, subtilte, subStat}) => {
        const isPos = stat > 0
        return <div key={name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:px-3 sm:pt-6 sm:pr-6">
            <dt className="text-sm font-medium text-gray-500 truncate">{name}</dt>
            <dd className={`mt-1 text-2xl font-semibold ${isPos ? 'text-green-600' : 'text-red-600'} flex space-x-reverse space-x-2`}>
                {isPos ? (<ChevronDoubleUpIcon className="w-4 mb-2 text-green-600"/>) : (
                    <ChevronDoubleDownIcon className="w-4 mb-2 text-red-600"/>)}
                {subStat ? (<Tooltip title={` بالدينار  ${formatNumber(subStat, null, 0)}`}>
                    <span>{formatNumber(stat, currency, round)}</span>
                </Tooltip>) : (
                    <span>{formatNumber(stat, currency, round)}</span>
                )}
            </dd>
            {subtilte && <dd className={`mt-1 text-sm text-gray-600`}>{subtilte}</dd>}
        </div>
    }


    // lines without loans and loan payback
    const creditLines = data?.lines?.filter(i => i.type === 'Credit' && i.categoryENG !== 'payback') || []
    const debitLines = data?.lines?.filter(i => i.type === 'Debit' && i.categoryENG !== 'loan') || []

    const paybackLines = data?.lines?.filter(i => i.categoryENG === 'payback') || []
    const loanLines = data?.lines?.filter(i => i.categoryENG === 'loan') || []


    const [showType, setShowType] = useState('الملخص');

    const isSummary = showType !== "الملخص"


    console.log(paybackLines,loanLines )
    return <Shell currentPage="balance">
        <PageHeading title={'القاصة'} subtitle={'صافي حسابات القاصة.'}/>

        <div className="my-3">
            <Segmented options={['الملخص', 'التفاصيل']} onChange={(e) => setShowType(e)}/>
        </div>

        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
            <Widget name={`صافي القاصة بالدولار`}
                    stat={sumBy(data.lines, i => i?.total?.usd)}
                    subStat={sumBy(data.lines, i => i?.total?.converted)}
                    currency={'USD'}/>
            <Widget name={`صافي القاصة بالدينار `}
                    stat={sumBy(data.lines, i => i?.total?.iqd)} currency={null} round={0}/>
            <Widget name={`صافي القاصة بمايعادلها بالدينار `}
                    stat={sumBy(data.lines, i => i?.total?.total)} currency={null}
                    round={0}/>
        </dl>

        {isSummary && (<>

            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                <Widget name={`مجموع الواردات بالدولار`} stat={sumBy(creditLines, i => i?.total?.usd)}

                        subStat={sumBy(creditLines, i => i?.total?.converted)}

                        currency={'USD'}/>
                <Widget name={`مجموع الواردات  بالدينار `} stat={sumBy(creditLines, i => i?.total?.iqd)} currency={null}
                        round={0}/>
                <Widget name={`مجموع الواردات بمايعادلها بالدينار `} stat={sumBy(creditLines, i => i?.total?.total)}
                        currency={null}
                        round={0}/>
            </dl>


            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                <Widget name={`مجموع المصاريف بالدولار`}
                        subStat={Math.abs(sumBy(debitLines, i => i?.total?.converted))}
                        stat={Math.abs(sumBy(debitLines, i => i?.total?.usd))}
                        currency={'USD'}/>
                <Widget name={`مجموع المصاريف  بالدينار `}
                        stat={Math.abs(sumBy(debitLines, i => i?.total?.iqd))}
                        currency={null}
                        round={0}/>
                <Widget name={`مجموع المصاريف بمايعادلها بالدينار `}
                        stat={Math.abs(sumBy(debitLines, i => i?.total?.total))}
                        currency={null}
                        round={0}/>
            </dl>

            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
                <Widget name={`مجموع الديون بالدولار`}
                        subStat={Math.abs(
                            sumBy(loanLines, i => i?.total?.converted) +
                            sumBy(paybackLines, i => i?.total?.converted)
                        )}
                        stat={Math.abs(
                            sumBy(loanLines, i => i?.total?.usd) +
                            sumBy(paybackLines, i => i?.total?.usd)
                        )}
                        currency={'USD'}/>
                <Widget name={`مجموع الديون  بالدينار `}
                        stat={Math.abs(
                            sumBy(loanLines, i => i?.total?.iqd) +
                            sumBy(paybackLines, i => i?.total?.iqd)
                        )}
                        currency={null}
                        round={0}/>
                <Widget name={`مجموع الديون بمايعادلها بالدينار `}
                        stat={Math.abs(
                            sumBy(loanLines, i => i?.total?.total) +
                            sumBy(paybackLines, i => i?.total?.total)
                        )}
                        currency={null}
                        round={0}/>
            </dl>

            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="الواردات" key="1">
                    <OwlTable
                        data={(data?.lines || []).filter(i => i.type === 'Credit')}
                        columns={columns}
                        isLoading={isLoading}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="المصروفات" key="2">
                    <OwlTable
                        data={(data?.lines || []).filter(i => i.type === 'Debit')}
                        columns={columns}
                        isLoading={isLoading}
                    />
                </Tabs.TabPane>
            </Tabs>
        </>)}

    </Shell>
}
