import {flexRender} from "@tanstack/react-table";

export function TableBody({table}) {
    return table.getRowModel().rows.map((row, index) => (
        <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
                <td {...{
                    key: cell.id,
                    style: {
                        width: cell.column.getSize(),
                    },
                    className: `whitespace-nowrap px-2 py-2 text-sm text-gray-900 ${index % 2 === 0 ? undefined : 'bg-gray-100'}`
                }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    ))
}
