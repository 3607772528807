import React from 'react';
import {BrowserRouter, Route, Routes, useNavigate, Outlet, Navigate} from "react-router-dom";
import {Main} from "./page/main";
import {About} from "./page/about";
import {Project} from "./page/project";
import {useAuth} from "./security";
import {NotFound} from "./page/NotFound";
import {SingupPage} from "./page/auth/signup";
import {LoginPage} from "./page/auth/login";
import {Projects} from "./page/projects";
import {Wages} from "./page/wages";
import {Wage} from "./page/wage";
import {WageAdd} from "./page/wage/wage-add";
import {Workers} from "./page/workers";
import {Worker} from "./page/worker";
import {Invoices} from "./page/invoices";
import {Loans} from "./page/loans";
import {Exchanges} from "./page/exchanges";
import {WageEdit} from "./page/wage/wage-edit";
import {Balance} from "./page/balance";
import {LogoutPage} from "./page/auth/logout";


export function AppRouter(props) {
    return <div style={{direction: 'rtl'}} className="bg-gray-200 v-full h-full">
        <BrowserRouter>
            <Routes>
                <Route exact path="/singup" element={<SingupPage/>}/>
                <Route exact path="/logout" element={<LogoutPage/>}/>
                <Route exact path="/login" element={<LoginPage/>}/>

                <Route element={<PrivateRoutes/>}>
                    <Route exact path="/" element={<Main/>}/>
                    <Route path="/about" element={<About/>}/>

                    <Route path="/project" element={<Projects/>}/>
                    <Route path="/project/:id" element={<Project/>}/>

                    <Route path="/worker" element={<Workers/>}/>
                    <Route path="/worker/:id" element={<Worker/>}/>

                    <Route path="/invoice" element={<Invoices/>}/>

                    <Route path="/loan" element={<Loans/>}/>

                    <Route path="/exchange" element={<Exchanges/>}/>

                    <Route path="/balance" element={<Balance/>}/>


                    <Route path="/wage" element={<Wages/>}/>
                    <Route exact path="/wage/new" element={<WageAdd/>}/>
                    <Route path="/wage/:id" element={<Wage/>}/>
                    <Route exact path="/wage/:id/edit" element={<WageEdit/>}/>
                    {/*<Route path="/wage/date/:date" element={<WagesWeekly/>}/>*/}


                </Route>

                <Route path='*' element={<NotFound/>}/>

            </Routes>
        </BrowserRouter>
    </div>
}

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
function PrivateRoutes() {

    const {isAuthenticated, isUserWithinScope} = useAuth()
    const navigate = useNavigate()

    console.log(isAuthenticated)

    // const isAllowed = isUserWithinScope(scope)
    // state={{from: location}}

    return isAuthenticated ? <Outlet/> : <Navigate to="/login"/>

}
