import {Button, Form, Input, Modal} from "antd";
import {useState} from "react";
import {rules} from "../../utils/rules";
import {useMutation} from "../../api";
import {useQueryClient} from "react-query";
import moment from "moment";
import {FileAddOutlined} from "@ant-design/icons";


export function AddProject() {

    const [form] = Form.useForm();
    const queryClient = useQueryClient()

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true)
        form.resetFields()
    }
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }
    const usePost = useMutation({url: '/project'})


    const handleOk = async () => {
        const values = await form.validateFields()
        usePost.mutate(values, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.setQueryData('/project', (oldData = []) =>
                    ({...oldData, results: [...oldData.results, newData]}))
            },
        })
    };

    return (<div>
        <Button type="primary" onClick={showModal} icon={<FileAddOutlined/>}>
            إضافة
        </Button>
        <Modal
            title="إصافة مشروع جديد"
            centered
            width={'70%'}
            destroyOnClose
            visible={isModalVisible}
            okText={"إضافة"}
            cancelText={"إلغاء"}
            onOk={handleOk}
            onCancel={onCancel}>
            <Form
                name="basic"
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                initialValues={{
                    total: {
                        usd: 0,
                        iqd: 0
                    },
                    date: moment(),
                }}
                autoComplete="off"
            >
                <Form.Item
                    label="آسم المشروع"
                    name="name"
                    rules={rules.input}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="المواصفات"
                    name="description"
                    rules={rules.input}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>
            </Form>
        </Modal>
    </div>);
}

export default AddProject;
